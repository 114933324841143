import { memo, useMemo, useState } from 'react';

import { CloseIconButton, FeedbackToast, Input, VirtualList, Link } from 'ui';
import { getLangName } from 'iso_639-1_codes';
import { TranslationRowText } from './TranslationRowText';
import { TranslationRowImage } from './TranslationRowImage';
import { TranslationRowAttribute } from './TranslationRowAttribute';
import { Placeholder } from './Placeholder';
import { TranslationsProvider } from './context';

import type { Props, TranslationNodes } from './Translations.types';

import style from './Translations.module.css';
import tabsStyle from 'views/ProjectElements/Tabs.module.css';

export const Translations = memo<Props>(
  ({
    language,
    nodes,
    alternatives,
    onNodeSet: setNode,
    onNodeRemove: removeNode,
    isSymbols,
  }) => {
    const [type, setType] = useState<'text' | 'image' | 'attribute'>(() =>
      nodes.text?.length ? 'text' : nodes.image?.length ? 'image' : 'attribute'
    );

    const key = useMemo(() => nodes && Date.now(), [nodes]);

    const [{ text, image, attribute }, setFilteredNodes] =
      useState<TranslationNodes>(nodes);

    const [filterValues, setFilterValues] = useState<{
      text: string;
      attribute: string;
    }>({ text: '', attribute: '' });

    const filter = (type: 'text' | 'image' | 'attribute', value: string) => {
      setFilterValues((values) => ({ ...values, [type]: value }));
      switch (type) {
        case 'text':
          setFilteredNodes((currentNodes) => ({
            ...currentNodes,
            text: nodes.text.filter((n) =>
              n.value.toLowerCase().includes(value)
            ),
          }));
          break;
        case 'attribute':
          setFilteredNodes((currentNodes) => ({
            ...currentNodes,
            attribute: nodes.attribute.filter((n) =>
              n.value.toLowerCase().includes(value)
            ),
          }));
      }
    };

    return (
      <div>
        <div className={style.head}>
          <div>
            <div className={style.title}>Edit translations</div>
            <p className={style.description}>
              Your changes will be saved automatically after a few seconds. If
              you do not want to change the original content, you do not need to
              add the translation.
            </p>
          </div>
          <div className={style.translationsInfo}>
            <FeedbackToast
              type="info"
              textSize="small"
              minimal
              text={
                <span>
                  If you add a translation but do not fill in the field, nothing
                  will be displayed on the website. If you want to keep the
                  original text, delete the translation using the bin icon.
                </span>
              }
            />
          </div>
        </div>
        <nav className={tabsStyle.root}>
          {nodes.text?.length ? (
            <button
              onClick={() => setType('text')}
              className={
                tabsStyle.item + (type === 'text' ? ' ' + tabsStyle.active : '')
              }
            >
              Text
            </button>
          ) : null}
          {nodes.image?.length ? (
            <button
              onClick={() => setType('image')}
              className={
                tabsStyle.item +
                (type === 'image' ? ' ' + tabsStyle.active : '')
              }
            >
              Image
            </button>
          ) : null}
          {nodes.attribute?.length ? (
            <button
              onClick={() => setType('attribute')}
              className={
                tabsStyle.item +
                (type === 'attribute' ? ' ' + tabsStyle.active : '')
              }
            >
              Attribute
            </button>
          ) : null}
        </nav>
        <div className={style.twoColumns}>
          <div>
            {type !== 'image' && (
              <div className={style.search}>
                <Input
                  fullWidth
                  placeholder="Search"
                  aria-label="Seach"
                  size="small"
                  value={filterValues[type]}
                  onChange={(e) => filter(type, e.target.value)}
                />
                {filterValues[type] && (
                  <CloseIconButton onClick={() => filter(type, '')} />
                )}
              </div>
            )}
          </div>
          {!isSymbols && (
            <FeedbackToast
              type="info"
              textSize="small"
              minimal
              text={
                <span>
                  Missing content?{' '}
                  <Link
                    text="Check the symbols tab"
                    url={`/projects/${'project.id'}/symbols`}
                    internal
                  />
                  , maybe that section you can't find is a Webflow symbol and
                  you will find it there.
                </span>
              }
              margin="bottom"
            />
          )}
        </div>
        <div className={style.headers}>
          <div>
            <div className={style.original}>Original content</div>
          </div>
          <div>
            <div className={style.translated}>{getLangName(language)}</div>
          </div>
        </div>
        <TranslationsProvider
          value={{ alternatives, type, setNode, removeNode }}
        >
          {/* TODO transform to router Outlet */}
          {type === 'text' ? (
            <VirtualList
              key={key}
              className={style.list}
              data={text}
              component={TranslationRowText}
              placeholder={Placeholder}
            />
          ) : type === 'image' ? (
            <VirtualList
              key={key}
              className={style.list}
              data={image}
              component={TranslationRowImage}
              placeholder={Placeholder}
            />
          ) : type === 'attribute' ? (
            <VirtualList
              key={key}
              className={style.list}
              data={attribute}
              component={TranslationRowAttribute}
              placeholder={Placeholder}
            />
          ) : null}
        </TranslationsProvider>
      </div>
    );
  }
);
