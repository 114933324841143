import style from './StepsList.module.css';

/**
 * To separate the items use css in a class of a wrapper element like this:
 *   ```
 *   .wrapper li:not(:last-child) {
 *     padding-bottom: 16px;
 *   }
 *   ```
 */
export const StepsList = ({
  steps,
}: {
  steps: { text: string; done?: boolean }[];
}) => {
  return (
    <ul className={style.stepsList}>
      {steps.map((s, i) => (
        <li key={i} className={s.done ? style.done : undefined}>
          {s.text}
        </li>
      ))}
    </ul>
  );
};
