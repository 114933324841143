export const ChromeExtensionLogo = ({ width }: { width: number }) => (
  <svg width={width ?? '100%'} viewBox="0 0 192 192">
    <rect id="bounding-box-1" y="0" fill="none" width="192" height="192"></rect>
    <g id="art-layer">
      <g>
        <path
          fill="#F1F3F4"
          d="M172,28H20v121.63c0,5.72,4.64,10.37,10.37,10.37h131.27c5.72,0,10.37-4.64,10.37-10.37L172,28L172,28z"
        ></path>
        <path
          fill="#F1F3F4"
          d="M172,28H20v121.63c0,5.72,4.64,10.37,10.37,10.37h131.27c5.72,0,10.37-4.64,10.37-10.37L172,28L172,28z"
        ></path>
        <rect x="20" y="28" fill="#E8EAED" width="152" height="66.35"></rect>
        <path
          fill="#FFFFFF"
          d="M113.27,56.34H78.73c-3.82,0-6.91-3.09-6.91-6.91l0,0c0-3.82,3.09-6.91,6.91-6.91h34.54    c3.82,0,6.91,3.09,6.91,6.91l0,0C120.18,53.24,117.09,56.34,113.27,56.34z"
        ></path>
        <g>
          <g>
            <g>
              <defs>
                <path
                  id="SVGID-1"
                  d="M172,28H20v121.63c0,5.72,4.64,10.37,10.37,10.37h131.27c5.72,0,10.37-4.64,10.37-10.37L172,28L172,28z        "
                ></path>
              </defs>
              <clipPath id="SVGID-00000069372319379759664590000016439136492548043392">
                <use xlinkHref="#SVGID-1" overflow="visible"></use>
              </clipPath>
              <g clipPath="url(#SVGID-00000069372319379759664590000016439136492548043392)">
                <linearGradient
                  id="SVGID-00000005268409276694585540000015853986965625284781"
                  gradientUnits="userSpaceOnUse"
                  x1="39.16"
                  y1="775.015"
                  x2="152.84"
                  y2="775.015"
                  gradientTransform="matrix(1 0 0 1 0 -650)"
                >
                  <stop offset="0" style={{ stopColor: '#d93025' }}></stop>
                  <stop offset="1" style={{ stopColor: '#ea4335' }}></stop>
                </linearGradient>
                <path
                  fill="url(#SVGID-00000005268409276694585540000015853986965625284781)"
                  d="M39.16,116.8l9.05,27.61l19.38,21.63        L96,116.81l56.84-0.01C141.49,97.18,120.29,83.99,96,83.99S50.51,97.18,39.16,116.8z"
                ></path>
                <linearGradient
                  id="SVGID-00000175289037886816495430000013271641968500087969"
                  gradientUnits="userSpaceOnUse"
                  x1="-1169.8269"
                  y1="59.7414"
                  x2="-1056.1232"
                  y2="59.7414"
                  gradientTransform="matrix(-0.5 -0.866 0.866 -0.5 -533.5404 -772.0297)"
                >
                  <stop offset="0" style={{ stopColor: '#1e8e3e' }}></stop>
                  <stop offset="1" style={{ stopColor: '#34a853' }}></stop>
                </linearGradient>
                <path
                  fill="url(#SVGID-00000175289037886816495430000013271641968500087969)"
                  d="M95.99,215.28l19.38-21.64l9.04-27.6H67.58        L39.16,116.8c-11.31,19.64-12.14,44.61,0.01,65.65C51.31,203.49,73.34,215.26,95.99,215.28z"
                ></path>
                <linearGradient
                  id="SVGID-00000025418581839675921930000004546302552040502431"
                  gradientUnits="userSpaceOnUse"
                  x1="56.7071"
                  y1="-664.7747"
                  x2="170.4071"
                  y2="-664.7747"
                  gradientTransform="matrix(-0.5 0.866 -0.866 -0.5 -403.9008 -264.818)"
                >
                  <stop offset="0" style={{ stopColor: '#fbbc04' }}></stop>
                  <stop offset="1" style={{ stopColor: '#fcc934' }}></stop>
                </linearGradient>
                <path
                  fill="url(#SVGID-00000025418581839675921930000004546302552040502431)"
                  d="M152.84,116.81H96l28.42,49.23L96,215.28        c22.66-0.02,44.69-11.79,56.83-32.83C164.98,161.41,164.15,136.45,152.84,116.81z"
                ></path>
                <ellipse
                  fill="#F1F3F4"
                  cx="96"
                  cy="149.63"
                  rx="32.81"
                  ry="32.82"
                ></ellipse>
                <ellipse
                  fill="#1A73E8"
                  cx="96"
                  cy="149.63"
                  rx="26.66"
                  ry="26.67"
                ></ellipse>
              </g>
            </g>
          </g>
        </g>
        <rect
          x="20"
          y="94.35"
          opacity="0.1"
          fill="#BDC1C6"
          enableBackground="new    "
          width="152"
          height="0.86"
        ></rect>
        <rect
          x="20"
          y="93.48"
          opacity="0.1"
          fill="#BDC1C6"
          enableBackground="new    "
          width="152"
          height="0.86"
        ></rect>
      </g>
    </g>
  </svg>
);
